.link {
    color: red;
    text-decoration:  none;
}

.mainHeader {
    animation: title-fade-in ease 2s;
    color:white;
    margin-left: 50px;
    margin-bottom: 10px;
}

.mainNav {
    justify-content: space-between;
}

#bc {
    font-family: 'Bungee', cursive;
    font-size:6.2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

#tag {
    font-size: 1.4rem;
}

@keyframes title-fade-in {
    0% {
        transform: translate3d(-1000px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@media only screen and (max-width: 768px) {
    .mainHeader {
        margin: auto;
    }
    #bc {
        font-size: 2rem;
    }

    #tag {
        font-size: .6rem;
    }
}



