.resumeHeader {
    margin: 25px 0;
    height: 12rem;
    background-color:#F87474;
    display: flex;
    justify-items: space-between;
    animation: header-slide-in ease 1.5s;
}

.resumeHeader > img { 
    margin: 10px;
    height: 10rem;
    width: auto;
    display: inline;
    border: 5px solid black;
    border-radius: 50%;
}

.resumeHeader > p {
    display: grid;
    font-family: 'Bungee';
    font-size: 4rem;
    place-content: center;
}


h1, p {
    margin: 5px;
}

.resumeEntry #langList {
    text-align: center;
    list-style: none;
}

.resumeEntry > ul {
    margin: 10px;
    list-style: disc;
}

.resumeEntry {
    border: 5px solid;
    border-radius: 10px;
    border-color: #3AB0FF;
    background-color: aliceblue;
    text-align:left;

}

.resumeEntry > h1 {
    font-family: 'Bungee';
}

.resumeContainer {
    display: grid;
    gap: 20px;
    margin: 25px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    padding: 25px;
    background-color: #FFFFFF;
}

#edu {
    grid-row: 1;
    grid-column: 1 / 3;
    animation: slide-up ease .6s;
}

#edu:hover {
    background-image: none;
}

#edu:hover > div {
    display:block;
}

#eduBody {
    display: none;
}

#work {
    animation: slide-up ease 1s
}

#languages {
    grid-column-start:3;
    grid-row-start: 1; 
    place-content: center;
    animation: slide-up ease .8s
}

#langList {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 300px;
    gap: 10px;
    justify-content: space-evenly
}

#experience {
    grid-row: 1 / 3;
    grid-column-start: 4;
    animation: slide-up ease 1.4s
}

#extra {
    grid-column: 2 / 4;
    animation: slide-up ease 1.2s
}

.resumeEntry li {
    margin: 1rem 0;
}

.entryBody {
    list-style: disc;
}

.header {
    font-weight: bold;
    color: #e26868
}

.tag {
    font-style: italic;
    display: inline;
    margin-left: auto;
    margin-right: auto;
}

.noBreak {
    display: inline;
    margin-right: 0;

}

@keyframes slide-up {
    0% {
        transform: translateY(3000px);
    }
    100% {
        transform: translateY(0);
    }
}