.eduHeader {
    height: 12rem;
    background-color: #F87474;
    margin-top: 25px;
    display: grid;
    align-items:center;
    justify-content: start;
    animation: slide-in-left ease 1.5s;
    animation-fill-mode: forwards;
}

.headerText {
    margin-left: 25px;
    font-family: 'Bungee';
    font-size: 4rem;
}

.eduNav {
    justify-self: end;
    margin-right: 25px;
    margin-top: 25px;
    list-style: none;
    grid-column: 1/2;
    grid-row-start: 1;
}

.eduNav > li > a {
    text-decoration: none;
}

.eduNav > li {
    margin: 10px 0
}

.eduNav > p {
    margin-left: 0;
}

.eduBody{
    justify-content: end;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr;
    animation: slide-in-right ease 1.5s
}

.semContainer .semHeader {
    display: block;
    font-family: 'Bungee';
    color: #38B0FF;
    font-size: 2rem;
    margin-top: 2rem;
}

.semsContainer {
    grid-column: 2 / 3;
}

.classCard {
    border: solid .25rem;
    border-color: #38B0FF;
    border-radius: 1rem;
    background-color: aliceblue;
}

.cardHeader {
    font-family: 'Bungee';
    size: .5rem;
}

.cardBody {
    size: .2rem;
}

.semClasses {
    display:grid;
    gap: 1%;
    margin: .5rem 1rem;
    width: 92%;
    margin-right: auto;
    grid-template-columns: repeat(4, 25%);
    background-color: white;
}

.eduNavLink {
    text-decoration: none;
    margin: 6rem 0;
    color:black;
    opacity:.8;
}
.eduNavLink:hover {
    color:#F87474;
}

.eduNavHeader {
    font-style: italic;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(3000px);
    } 
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(-3000px);
    }
    100% {
        transform: translateX(0);
    }
}