:root {
    --mycolor: #24253b
}

.footer {
    background-color: #FA9494;
    height: 10vh;
    width: 100%;
    text-align: center;
    display: grid;
    place-content: center;

    

    position: static;
    bottom: 0;
}

.footer > p {
    color: grey
}