.aboutHeader {
    font-size: 160%;
    color: white;
    align-items: center;
    height: 12rem;
    display: grid;
    background-color: #F87474;
    margin-top: 1.5%;
    margin-bottom: 5rem;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    place-content: center;

    animation: header-slide-in ease 1.5s;
    animation-fill-mode: forwards;
}


.polaroid {
    box-shadow: .75rem .5rem .25rem rgba(0, 0, 0, 0.2);
    margin: 0 ;
    grid-column-start: 3;
    width: 12.5vw;
    height: auto;
    grid-row-start: 1;
    display: grid;
    align-items: end;
    justify-items: end;
}

.polaroid:hover {
    animation: rotate ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#pic {
    width: 100%;
    display: flex;
    justify-self: end;
}

.container {
    background-color: white;
    grid-row-start: 2;
    width: 12.5vw;
    height: 100%;
}

.aboutHeader {
    text-align: center;
}

#headerTag {
    grid-column-start: 2;
}
/*
  @media screen and (max-width: 1380px) {
    #pic {
        display: none;
    }

    #container {
        display: none;
    }

    #polaroid {
        display: none;
    }
    */

#caption {
    color: black;
    font-size: large;
}

#aboutBody {
    display: grid;
    place-content: center;
    padding-bottom: 75px;

    animation: slide-fade-in ease 2.5s;
    animation-fill-mode: forwards;
}

.intro {
    font-style: italic;
    font-size: 2rem;
    color: #38B0FF;
}

.contactImg {
    width: 6rem;
    height: auto;
}

#contactList {
    list-style: none;
}

#contactList > li {
    display: inline;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

#contactList > li > a {
    text-decoration: none;
    color: black;
}

#contactList > li > a:hover {
    color:#F87474;
}

#contactList {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

#aboutParagraph {
    background-color: aliceblue;
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    text-align: center;
    padding: 1.5rem;
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;

    box-shadow: 10px 10px 10px #38B0FF;
}

#headerTag > p {
    margin: 0;
    font-style: italic;
}

#line1 {
    font-family: 'Bungee';
}

@keyframes slide-fade-in {
    0% {
        opacity: 0;
        transform: translateY(-1000px);
    }
    50% {
        opacity: 0;
        transform: translateY(-1000px);
    }
    75% {
        opacity: .25;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes header-slide-in {
    0% {
        transform: translate3d(3000px, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fade-in {
    0% { opacity: 0 }
    50% { opacity: 0 }
    100% { opacity: 1 }
}

@media only screen and (max-width: 768px) {
    .aboutHeader {
        font-size: .5rem;
        height: 8rem;
    }

    .intro {
        font-size: .3rem;
    }

    #aboutParagraph {
        font-size: .15rem;
    }
    #aboutBody {
        margin: 1rem 3rem;
    }

    .polaroid {
        width: 100%;
        display:block;
        font-size: .3rem;
    }

    .contactImg {
        width: 1rem;
    }
}